.frahm-welcome{
    background: #FFF;
    width:45%;
    margin: 0 auto;
    padding: 1em;
    border-radius: 8px;
    text-align: center;
}

.max-h-100px{
    max-height: 100px;
}

.max-h-130px{
    max-height: 180px;
}

.max-w-150px{
    max-width: auto;
}

.max-h-80px{
    max-height: 80px;
}

.hidden {
    display:none!important;
}

//Buttons MK add, export

.add-new{
    margin:0 0.5em;

    i{
        padding-right:0;
    }
}

//Icon Zwischengroesse
.icon-1-5x{
    font-size:1.5rem!important;
}

//Status Advice
.advicestatus{
    width: 14px;
    height: 14px;
    border-radius: 8px;
}
.red{
    background-color: #f64e60 !important;
}
.orange{
    background-color: #ffa800 !important;
}
.yellow{
    background-color:yellow !important;
}
.green{
    background-color:#1bc5bd !important;
}

//Datatable Accordeon Aufklapper
.dtr-data{
    width:100% !important;
}

.dataTables_wrapper .child .dtr-details > li span.dtr-title:after{
    content: "" !important;
}

.dtr-data .container-fluid .progress{
    width:50%;
}

.nowrap, .dt-nowrap{
    white-space: nowrap;
}


table.dataTable.dtr-inline.collapsed > tbody > tr > td.dtr-control::before, table.dataTable.dtr-inline.collapsed > tbody > tr > th.dtr-control::before{
    font-weight: bold;
    font-size: 1.2rem;
    margin-top: -2rem;
    padding-top: -2rem;;
    margin-left:-1rem;
    margin-right: 1.2rem;
}
.lfdnummer{
    display: table-cell;
    font-weight: 400;
    font-size: 1rem;
    vertical-align: middle;
    color: #3F4254;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease;
    outline: none !important;
    padding: 1rem 1.5rem;
}

//Label Card
.card.card-custom > .card-header .card-title .card-label{
    margin:0.75rem 0 0.75rem 0;
}

//Progress Bar Module
.progress{
    margin-top:1.5rem;
}

//Text
.text-bold{
    font-weight: 800;
}

//Wizzard 4
.wizard.wizard-4 {
    -webkit-box-orient:vertical;
    -webkit-box-direction:normal;
    -ms-flex-direction:column;
    flex-direction:column
   }
   .wizard.wizard-4 .wizard-nav .wizard-steps {
    display:-webkit-box;
    display:-ms-flexbox;
    display:flex;
    -webkit-box-align:end;
    -ms-flex-align:end;
    align-items:flex-end;
    -webkit-box-pack:justify;
    -ms-flex-pack:justify;
    justify-content:space-between;
    -ms-flex-wrap:wrap;
    flex-wrap:wrap
   }
   .wizard.wizard-4 .wizard-nav .wizard-steps .wizard-step {
    display:-webkit-box;
    display:-ms-flexbox;
    display:flex;
    -webkit-box-align:center;
    -ms-flex-align:center;
    align-items:center;
    -webkit-box-pack:center;
    -ms-flex-pack:center;
    justify-content:center;
    -ms-flex-wrap:wrap;
    flex-wrap:wrap;
    -webkit-box-flex:0;
    -ms-flex:0 0 calc(25% - .25rem);
    flex:0 0 calc(25% - .25rem);
    width:calc(25% - .25rem);
    background-color:#f3f6f9;
    border-top-left-radius:.5rem;
    border-top-right-radius:.5rem
   }
   .wizard.wizard-4.wizard-2 .wizard-nav .wizard-steps .wizard-step {
    display:-webkit-box;
    display:-ms-flexbox;
    display:flex;
    -webkit-box-align:center;
    -ms-flex-align:center;
    align-items:center;
    -webkit-box-pack:center;
    -ms-flex-pack:center;
    justify-content:center;
    -ms-flex-wrap:wrap;
    flex-wrap:wrap;
    -webkit-box-flex:0;
    -ms-flex:0 0 calc(50% - .25rem);
    flex:0 0 calc(50% - .25rem);
    width:calc(50% - .25rem);
    background-color:#f3f6f9;
    border-top-left-radius:.5rem;
    border-top-right-radius:.5rem
   }
   .wizard.wizard-4 .wizard-nav .wizard-steps .wizard-step .wizard-wrapper {
    -webkit-box-flex:1;
    -ms-flex:1;
    flex:1;
    display:-webkit-box;
    display:-ms-flexbox;
    display:flex;
    -webkit-box-align:center;
    -ms-flex-align:center;
    align-items:center;
    -ms-flex-wrap:wrap;
    flex-wrap:wrap;
    color:#3f4254;
    padding:2rem 2.5rem
   }
   .wizard.wizard-4 .wizard-nav .wizard-steps .wizard-step .wizard-wrapper .wizard-number {
    font-size:1.3rem;
    font-weight:600;
    -webkit-box-flex:0;
    -ms-flex:0 0 2.75rem;
    flex:0 0 2.75rem;
    height:2.75rem;
    width:2.75rem;
    display:-webkit-box;
    display:-ms-flexbox;
    display:flex;
    -webkit-box-align:center;
    -ms-flex-align:center;
    align-items:center;
    -webkit-box-pack:center;
    -ms-flex-pack:center;
    justify-content:center;
    background-color:rgba(54,153,255,.08);
    color:#3699ff;
    margin-right:1rem;
    border-radius:.5rem
   }
   .wizard.wizard-4 .wizard-nav .wizard-steps .wizard-step .wizard-wrapper .wizard-label {
    display:-webkit-box;
    display:-ms-flexbox;
    display:flex;
    -webkit-box-orient:vertical;
    -webkit-box-direction:normal;
    -ms-flex-direction:column;
    flex-direction:column
   }
   .wizard.wizard-4 .wizard-nav .wizard-steps .wizard-step .wizard-wrapper .wizard-label .wizard-title {
    font-size:1.1rem;
    font-weight:600
   }
   .wizard.wizard-4 .wizard-nav .wizard-steps .wizard-step[data-wizard-state=current] {
    background-color:#fff
   }
   .wizard.wizard-4 .wizard-nav .wizard-steps .wizard-step[data-wizard-state=current] .wizard-wrapper .wizard-number {
    color:#fff;
    background-color:#3699ff
   }
   .wizard.wizard-4 .wizard-nav .wizard-steps .wizard-step[data-wizard-state=current] .wizard-wrapper .wizard-label .wizard-title {
    color:#3699ff
   }
   .wizard.wizard-4 .wizard-nav .wizard-steps[data-total-steps="2"] .wizard-step {
    -webkit-box-flex:0;
    -ms-flex:0 0 calc(50% - .25rem);
    flex:0 0 calc(50% - .25rem);
    width:calc(50% - .25rem)
   }
   .wizard.wizard-4 .wizard-nav .wizard-steps[data-total-steps="3"] .wizard-step {
    -webkit-box-flex:0;
    -ms-flex:0 0 calc(33.3333333333% - .25rem);
    flex:0 0 calc(33.3333333333% - .25rem);
    width:calc(33.3333333333% - .25rem)
   }
   .wizard.wizard-4 .wizard-nav .wizard-steps[data-total-steps="4"] .wizard-step {
    -webkit-box-flex:0;
    -ms-flex:0 0 calc(25% - .25rem);
    flex:0 0 calc(25% - .25rem);
    width:calc(25% - .25rem)
   }
   @media (max-width:1399.98px) {
    .wizard.wizard-4 .wizard-nav .wizard-steps .wizard-step {
     -webkit-box-flex:0;
     -ms-flex:0 0 calc(50% - .25rem);
     flex:0 0 calc(50% - .25rem);
     width:calc(50% - .25rem);
     border-bottom-left-radius:.5rem;
     border-bottom-right-radius:.5rem;
     margin-bottom:.5rem
    }
    .wizard.wizard-4 .wizard-nav .wizard-steps[data-total-steps="2"] .wizard-step,
    .wizard.wizard-4 .wizard-nav .wizard-steps[data-total-steps="4"] .wizard-step {
     -webkit-box-flex:0;
     -ms-flex:0 0 calc(50% - .25rem);
     flex:0 0 calc(50% - .25rem);
     width:calc(50% - .25rem)
    }
    .wizard.wizard-4 .wizard-nav .wizard-steps[data-total-steps="3"] .wizard-step {
     -webkit-box-flex:0;
     -ms-flex:0 0 100%;
     flex:0 0 100%;
     width:100%
    }
   }
   @media (max-width:767.98px) {
    .wizard.wizard-4 .wizard-nav .wizard-steps {
     -webkit-box-orient:vertical;
     -webkit-box-direction:normal;
     -ms-flex-direction:column;
     flex-direction:column;
     -webkit-box-align:start;
     -ms-flex-align:start;
     align-items:flex-start
    }
    .wizard.wizard-4 .wizard-nav .wizard-steps .wizard-step {
     -webkit-box-flex:0!important;
     -ms-flex:0 0 100%!important;
     flex:0 0 100%!important;
     position:relative;
     width:100%!important
    }
    .wizard.wizard-4 .wizard-nav .wizard-steps .wizard-step .wizard-wrapper {
     -webkit-box-pack:start;
     -ms-flex-pack:start;
     justify-content:flex-start;
     -webkit-box-flex:0;
     -ms-flex:0 0 100%;
     flex:0 0 100%;
     padding:.5rem 2rem
    }
   }
   
   //Documenttemplates
   .custom-card-header{
    padding: 2rem 2.25rem !important;
   }

   /* Medicine */
   
   .nav-tabs{

        .nav-item {
            

            .nav-link{
            padding: 2rem 2.5rem;

                .nav-icon {
                margin-right: 1rem;
                vertical-align: middle;
                i{
                    height: 2.75rem;
                    width: 2.75rem;
                    font-size:2rem;
                }
                }
        
        .nav-text{
            font-size:1.1rem;
            font-weight:600;
            line-height: 1.4rem;

            small{
                font-size:0.98rem;
            }
        }
   }
}
}

/* Healthcard Detail*/
.healthcard-title{
    font-size:1.5rem !important;
    color: #3F4254;
}

.healthcard-branch, .healthcard-entree{
    padding: 1rem;
    margin:1rem 1rem 1rem 0;
    color: #2b2d36;
    font-size: 1.1rem;
    line-height: 1.6rem;
}
.healthcard-entree{
    margin:0.5rem  0 0.5rem 0.5rem;
}